<template>
  <div>
    <div class="success-page">
      <img src="@/assets/images/svg/mobile-payment.png" alt="" />
      <h1>{{$t("Payment Successful!")}}</h1>
      <p>
        Thank you for your purchase! Your payment has been processed successfully.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sessionId: null, // Initialize sessionId
    };
  },
  created() {
    this.stripeSession();
  },
  methods: {
    async stripeSession() {
      this.sessionId = this.$route.query.session_id;
      if (this.sessionId) {
        this.$store.commit("showLoader", true);

        await this.$store
          .dispatch("packages/stripeSession", {
            sessionId: this.sessionId,
          })
          .then((res) => {
            this.$store.commit("showLoader", false);
            this.$store.dispatch("customers/getCredits");
          })
          .finally(() => {
            this.$store.commit("showLoader", false);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.success-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  padding: 100px 0;
  img {
    width: 300px;
  }
}

.btn {
  margin: 10px;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff; /* Bootstrap primary color */
}

.btn-secondary {
  background-color: #6c757d; /* Bootstrap secondary color */
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
